<template>
  <!--
    Used to display the layout image elements that are used in the videoPostprocessing themes.
  -->
  <Portlet
    :title="portletTitle"
    class="themeSoundList"
    icon="clone"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <ThemeElementTabs
        :theme-id="themeId"
      />
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(themeSoundElements)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(themeSoundElements)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmRemoveModal(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>Remove</span>
              </button>
            </div>
          </td>
        </template>
        <template
          slot="checkBoxTemplate"
          slot-scope="{props}"
        >
          <td
            :style="props.dataItem.groupId ? getColor(props.dataItem.groupId) : ''"
            class="text-center"
          >
            <div
              v-if="props.dataItem.category == 'PostStart' || props.dataItem.category == 'PreStart'"
              class="form-check"
            >
              <input
                :id="`checkbox_${ props.dataItem.soundElementId }`"
                v-model="props.dataItem.checked"
                v-tooltip="linkTooltip"
                type="checkbox"
                class="form-check-input alt-pointer"
                @change="addLinkElement($event.target.checked, props.dataItem)"
              >
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <ThemeSoundAdd
          v-if="addingElement"
          :theme-id="themeId"
          @reload="reloadThemeSounds"
        />
      </Sidebar>
    </template>
    
    <div class="mt-4">
      <router-link
        :to="`/videopostprocessing/theme`"
        class="btn btn-sm btn-secondary float-left"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="caret-left"
        />{{ $t('back') }}
      </router-link>
      <button
        class="btn btn-sm btn-primary float-right"
        @click="showSidebarAdd()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="plus"
        />{{ $t('add') }}
      </button>
      <button
        v-if="elementsToLink.length == 2"
        class="btn btn-primary float-right mr-2"
        @click="linkPrePostStart()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="link"
        />
        <span>Link</span>
      </button>
      <div class="clearfix" />
    </div>

    <SweetModal
      ref="confirmDelete"
      icon="warning"
      blocking
      title="Delete Theme Sound?"
      class="overflowHidden"
    >
      <p>{{ $t('themeSoundListComp.sureToDelete') }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeThemeSoundElement()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </Portlet>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "ThemeSoundList",
  components: {
    SweetModal,
    ThemeElementTabs: () => import('@/components/VideoPostprocessing/Theme/ThemeElementTabs.vue'),
    ThemeSoundAdd: () => import('@/components/VideoPostprocessing/Theme/ThemeSoundAdd.vue'),
    Sidebar: () => import('@/components/Base/Sidebar.vue')
  },
  mixins: [
    kendoGridMixin
  ],
  props: {
    themeId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      theme: null,
      elementsToLink: [],
      groupIdColors: [],
      groupToDeleteId: null,
      themeSoundElements: null,
      loading: true,
      indexToDelete: null,
      showSidebar: false,
      addingElement: false,
      currentId: null,
      colors: [
        'red',
        'blue',
        'green',
        'firebrick',
        'black',
        'aqua',
        'cadetblue',
        'chartreuse',
        'coral',
        'cornflowerblue',
        'crimson',
        'darkorange',
        'deeppink',
        'brown',
        'pink',
        'darkblue',
        'darkgreen',
        'lightblue'
      ],
      linkTooltip: "Please select one PreStart and one PostStart element to link",
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'name',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_columns: [
        {
          filterable: false,
          title: '',
          cell: 'checkBoxTemplate',
          width: '50px'
        },
        {
          filterable: false,
          title: 'Sound',
          field: 'name',
        },
        {
          filterable: false,
          title: 'Category',
          field: 'category',
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          width: '120px'
        }
      ]
    }
  },
  computed: {
    portletTitle () {
      if (this.theme == null) {
        return this.$t('userDependentImageListComp.theme');
      }
      if (this.theme.name == null) {
        return this.$t('userDependentImageListComp.theme')
      }
      return `${ this.$t('userDependentImageListComp.theme') }: ${ this.theme.name }`
    }
  },
  async created () {
    await this.getTheme();
    this.getThemeSoundElements();
  },
  methods: {
    //#region Sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.show();
    },
    //#endregion
    //#region API-calls
    reloadThemeSounds () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getThemeSoundElements();
    },
    openConfirmRemoveModal (item) {
      this.indexToDelete = item.soundElementId;
      this.groupToDeleteId = item.groupId  ? item.groupId : "-1";
      this.$refs.confirmDelete.open();
    },
    removeThemeSoundElement () {
      this.axios.delete(`/VideoPostProcessing/DeleteSoundElement2Theme?soundElementId=${ this.indexToDelete }&themeId=${ this.themeId }&groupId=${ this.groupToDeleteId }` )
        .then(() => {
          this.getThemeSoundElements();
          this.$emit("reloadAuditLogs");
          this.$snotify.success(this.$t('themeSoundListComp.deletedSuccessfully'));
        })
        .finally(() => {
          this.indexToDelete = null;
          this.$refs.confirmDelete.close();
        });
    },
    getThemeSoundElements () {
      this.axios.get(`/VideoPostProcessing/GetAllSoundElement2Theme?themeId=${ this.themeId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          
          this.themeSoundElements = response.data;
          this.groupIdColors = [];
          this.themeSoundElements.forEach(element => {
              if(element.groupId && this.groupIdColors.filter(p=>p.groupId == element.groupId).length == 0)
              {
                let groupIdColor = 
                {
                  'groupId': element.groupId,
                  'color': this.colors[this.groupIdColors.length]
                }
                this.groupIdColors.push(groupIdColor);
              }
           });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getTheme ()
    {
      await this.axios.get(`/VideoPostProcessing/GetTheme?themeId=${ this.themeId }`)
        .then((response) => {
          this.theme = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    addLinkElement (isChecked, item) {
        let soundElement = {
          'themeId': item.themeId,
          'soundElementId': item.soundElementId,
          'category': item.category
        }
        if (isChecked) //add if checked
        {
          this.elementsToLink.push(soundElement);
        }
        else //remove if unchecked
        {
          this.elementsToLink = this.elementsToLink.filter(o => o.soundElementId != soundElement.soundElementId);
        }
      },
      linkPrePostStart () {
        this.axios.put(`/VideoPostProcessing/LinkSound2ThemeElements`, this.elementsToLink)
          .then((response) => {
            if (response && response.status && response.status == 200) {
              this.elementsToLink.forEach(element => {
                document.getElementById('checkbox_'+ element.soundElementId).checked = false;
              });
              this.elementsToLink = [];
              this.getThemeSoundElements();
              this.$emit("reloadAuditLogs");
              this.$snotify.success("Sounds successfully linked!");
            }
          });
      },
      getColor (groupId) {
        let color= this.groupIdColors.find(p => p.groupId == groupId).color;
        return `border-left:4px solid ${ color}`;
      }
    //#endregion
  }
}
</script>
<style scoped>
.form-check-input {
  margin-top: -.3rem;
}

</style>
<style>
.themeSoundList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>